import React from "react";
import {OurHistory} from "./our-history/OurHistory";
import {DaySchedule} from "./day-schedule/DaySchedule";

interface Page {
    pageName: string,
    elementId: string,
    content: React.FC
}

export const PAGES: Page[] = [{elementId: "OurHistory", content: OurHistory, pageName: "Nasza Historia"}, {elementId: "DaySchedule", content: DaySchedule, pageName: "Plan dnia"}];