import {Box} from "@mui/material";
import React, {useMemo} from "react";
import {useDeviceType} from "../../hook/useDeviceType";

interface TimeDisplayProps {
    value: number;
    name: TimeElement;
}

export enum TimeElement {
    DAYS, HOURS, MINUTES, SECONDS
}

export const TimeDisplay = (props: TimeDisplayProps) => {
        const {value} = props;
        const {isMobile} = useDeviceType();
        const label = useMemo((): string => {
            switch (props.name) {
                case TimeElement.DAYS:
                    return value === 1 ? 'Dzień' : 'Dni';
                case TimeElement.HOURS:
                    if (value === 1) {
                        return 'Godzina';
                    }
                    if (value > 1 && value < 5) {
                        return 'Godziny';
                    }
                    return 'Godzin';
                case TimeElement.MINUTES:
                    if (value === 1) {
                        return 'Minuta';
                    }
                    if (value > 1 && value < 5) {
                        return 'Minuty';
                    }
                    return 'Minut';
                case TimeElement.SECONDS:
                    if (value === 1) {
                        return 'Sekunda';
                    }
                    if (value > 1 && value < 5) {
                        return 'Sekundy';
                    }
                    return 'Sekund';
                default:
                    return props.name;
            }
        }, [props.name, value]);

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: 'center',
                    textAlign: "center",
                    width: isMobile ? '30vw' : '12vw',
                    aspectRatio: '1',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    boxShadow: '0px 0px 20px 3px rgba(255, 255, 255, 0.5)'
                }}
            >
                <div style={{fontSize: isMobile ? '6vw' : '4vw'}}>{value}</div>
                <div style={{fontSize: isMobile ? '3vw' : '1.5vw'}}>{label}</div>
            </Box>
        );
    }
;
