import {styled} from "@mui/material";
import {Link} from 'react-scroll';
import {useDeviceType} from "../../hook/useDeviceType";

interface NavigationItemProps {
    sectionName: string
    scrollTo: string
    isSelected: boolean
    changeSelectedTab: (newTabName: string) => void
}

export const NavigationItem = (props: NavigationItemProps) => {
    const {isMobile} = useDeviceType();
    return <StyledLink
        isMobile={isMobile}
        to={props.scrollTo}
        spy={true}
        smooth={true}
        offset={isMobile ? -250 : -400}
        selected={props.isSelected}
        onClick={() => props.changeSelectedTab(props.scrollTo)}
        duration={(distance) => {
            return Math.abs(distance) / 2;
        }}
    >{props.sectionName}
    </StyledLink>
}

export const StyledLink = styled(Link)<{isMobile: boolean}>`
  color: white;
  width: 25vw;
  max-width: 250px;
  height: ${props => props.isMobile ? '3vh' : '4vh'};
  text-align: center;
  transition: all 0.2s;
  font-size: ${props => props.isMobile ? '3vw' : '1vw'};
  
  &:after {
    content: '';
    display: block;
    position: relative;
    bottom: ${props => props.isMobile ? '-10px' : '-20px'};
    width: 50%;
    left: 25%;
    border-bottom: ${props => props.selected ? '2px solid rgb(255,255,255)': '0px'};
  }
  &:hover {
    font-size: ${props => props.isMobile ? '3vw' : '1.2vw'};
    cursor: pointer;
  }
`