import {DayElement, DayElementProps} from "./DayElement";
import {Box} from "@mui/material";
import {useDeviceType} from "../../hook/useDeviceType";

const dayElements: DayElementProps[] = [
    {
        name: "Błogosławieństwo",
        time: "14:30",
        address: "Zabrodzie 1A/4"
    },
    {
        name: "Ślub",
        time: "15:30",
        address: "Kościół p.w. Znalezienia i Podwyższenia Krzyża Św. w Korycinie",
    },
    {
        name: "Wesele",
        time: "~17:00",
        address: "Majatek Howieny, Pomigacze 91",
    },
]
export const DaySchedule = () => {
    const {isMobile} = useDeviceType();

    return (
        <Box sx={{display: "flex", flexDirection: isMobile ? "column" : "row", gap: '4vw'}}>
            {dayElements.map(element => <DayElement name={element.name} address={element.address}
                                                    time={element.time}/>)}
        </Box>
    )
}
