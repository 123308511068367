import {Dialog, styled} from "@mui/material";
import * as React from "react";

interface DialogProps {
    isOpened: boolean,
    onClose: () => void,
    children?: React.ReactNode;
}

export const StyledDialog = (props: DialogProps) => {
    const {isOpened, onClose} = props;
    return <BootstrapDialog open={isOpened} onClose={onClose} maxWidth={'lg'}>
        {props.children}
    </BootstrapDialog>
}

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minWidth: '40vw',
        aspectRatio: '1.5'
    },
    '& .MuiDialog-paper': {
        padding: theme.spacing(0.5),
        backgroundColor: 'black'
    },
}));