import {Box, styled} from "@mui/material"
import React from "react";

interface SectionProps {
    name: string,
    header: string,
    children?: React.ReactNode;
}

export const Section = (props: SectionProps) => {
    return (
        <StyledSection id={props.name}>
            <p style={{color: 'rgb(143, 188, 143)', fontSize: '40px', fontWeight: '800'}}>{props.header}</p>
            {props.children}
        </StyledSection>
    )
}

export const StyledSection = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
})