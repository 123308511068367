import {useEffect, useLayoutEffect, useState} from "react";

export enum SCREEN_SIZE {
    MOBILE = 'MOBILE',
    TABLET = 'TABLET',
    SMALL_LAPTOP = 'SMALL_LAPTOP',
    DESKTOP = 'DESKTOP',
    LARGE = 'LARGE',
}
export const BREAKPOINTS_MAP = {
    [SCREEN_SIZE.MOBILE]: 480,
    [SCREEN_SIZE.TABLET]: 768,
    [SCREEN_SIZE.SMALL_LAPTOP]: 1024,
    [SCREEN_SIZE.DESKTOP]: 1200,
    [SCREEN_SIZE.LARGE]: -Infinity,
};
export const useDeviceType = () => {
    const [size, setSize] = useState<SCREEN_SIZE>();
    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setSize(SCREEN_SIZE.MOBILE);
            }
            if (width > 480 && width <= 768) {
                setSize(SCREEN_SIZE.TABLET);
            }
            if (width > 768 && width <= 1024) {
                setSize(SCREEN_SIZE.SMALL_LAPTOP);
            }
            if (width > 1024 && width <= 1200) {
                setSize(SCREEN_SIZE.DESKTOP);
            }
            if (width > 1201) {
                setSize(SCREEN_SIZE.LARGE);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth]);

    useEffect(() => {
        setIsMobile(size === SCREEN_SIZE.MOBILE || size === SCREEN_SIZE.TABLET);
    }, [size]);

    return {
        size,
        isMobile,
    };
};