import React, {useEffect, useState} from 'react';
import './App.css';
import {Header} from './components/header/Header';
import styled from '@emotion/styled';
import {Box, ThemeProvider} from '@mui/material';
import background from "./images/background3.png"
import mobileBackground from "./images/background3-mobile.png"
import {DateTimeCounter} from "./components/counter/DateTimeCounter";
import {GlobalTheme} from './theme/global-theme';
import {Section} from "./components/section/Section";
import {PAGES} from "./pages/pages";
import {useDeviceType} from "./hook/useDeviceType";
import {Footer} from "./components/footer/Footer";

function App() {
    const [isPinned, setIsPinned] = useState<boolean>(false);
    const {isMobile} = useDeviceType();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < window.innerHeight * 0.78) {
                setIsPinned(false);
            } else {
                setIsPinned(true);
            }
        });
    }, [])

    return (
        <ThemeProvider theme={GlobalTheme}>
            <PageContent>
                <BackgroundImage className="App" sx={{backgroundImage: isMobile ? ` url(${mobileBackground})` : `url(${background})` }}/>
                <BlankPageSeparator/>
                <BlankPageSeparator/>
                <Header isPinned={isPinned}/>
                <BlankPageSeparator/>
                <DateTimeCounter date={new Date('2024-10-04T14:30:00.00Z')}/>
                <BlankPageSeparator/>
                <BlankPageSeparator/>
                {PAGES.map(page => <Section name={page.elementId} header={page.pageName}><page.content/></Section>)}
                {/*<Section name={'FAQ'} content='Majątek Howieny'></Section>*/}
                <Footer/>
            </PageContent>
        </ThemeProvider>
    )
}

const PageContent = styled(Box)({
    width: '100%',
    minHeight: '100vh',
    backdropFilter: 'blur(15px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})

const BackgroundImage = styled(Box)({
    backgroundRepeat: `no-repeat`,
    backgroundSize: 'cover',
    width: '100%',
    color: "white",
    minHeight: '100vh',
    position: 'absolute'
})

const BlankPageSeparator = styled(Box)({
    height: '10vh'
})

export default App;