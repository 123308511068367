import {Box, IconButton, styled} from "@mui/material";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import PhotoIcon from '@mui/icons-material/Photo';
import React, {useState} from "react";
import {VideoDialog} from "./VideoDialog";
import {PhotoDialog} from "./PhotoDialog";
import videoThumbnail from "../../images/video-thumbnail.png";
import imagesThumbnail from "../../images/images-thumbnail.png";

export const OurHistory = () => {
    const [isVideoDialogVisible, setIsVideoDialogVisible] = useState<boolean>(false);
    const [isPhotoDialogVisible, setIsPhotoDialogVisible] = useState<boolean>(false);

    return (
        <div style={{display: "flex", flexDirection: "column", height: '100%', width: '100%'}}>
            <Column style={{fontSize: '18px', gap: '1rem'}}>
                <TextContainer>
                    <p>“Wszystko zaczęło się przypadkiem, choć warto przypomnieć, że
                        przypadek to świeckie imię Opatrzności Bożej.” - to cytat, który
                        bardzo trafnie opisuje Nasze poznanie się.
                        Była niechlubna 4 nad ranem w sobotę pewnego dość ciepłego listopada
                        kiedy pierwszy raz się zobaczyliśmy. Tego dnia miało nas tam nawet nie
                        być. Mnie wyciągnęły z domu koleżanki, chociaż do ostatniej chwili
                        miałam ochotę im odmówić, Damiana na imprezę namówił brat. Całą noc
                        zapewne mijaliśmy się wśród roztańczonych ludzi, jednak dopiero pod
                        klubem Damian mnie zauważył i zagadał. 2 dni później siedzieliśmy na
                        Naszej oficjalnej pierwszej randce w kinie i do tej pory dziwię się,
                        że Nas stamtąd nie wyrzucono, ponieważ przegadaliśmy cały film i nie
                        zwracaliśmy na nic dookoła uwagi i tak jest aż do dziś - dalej nie
                        możemy się z sobą nagadać.</p>
                    <p>
                        Połączyło Nas dosłownie wszystko, od zamiłowania do gotowania, przez
                        ogromną rodzinność, miłość do podróży, zawziętość do dążenia do
                        postawionych sobie celów, aż po niemalże identyczne wizje na Naszą
                        przyszłość. Uwierzcie, że jednocześnie doskonale się uzupełniamy i ten
                        kto Nas dobrze zna, wie, że stanowimy zespół idealny. Nie ma rzeczy,
                        która jest dla Nas wyzwaniem. Uwielbiamy uczyć się nowych rzeczy i
                        znajdować coraz to nowsze kierunki podróży. No właśnie…
                    </p>
                    <p>
                        Podróże to nieodłączna część Naszego wspólnego życia. W Naszym ponad
                        4-letnim związku odwiedziliśmy kilkanaście państw i kolejnych kilka
                        mamy już w planach.
                    </p>
                    <p>
                        Właśnie na jednej z takich wypraw Damian postanowił zadać mi
                        najważniejsze pytanie “czy zostanę jego żoną”, chociaż o mały włos i
                        zaręczyn by wtedy nie było 😅cofnijmy się zatem kilka dni przed
                        najpiękniejszym zachodem słońca w Barcelonie z widokiem na całe
                        miasto. Mój wspaniały, wtedy jeszcze chłopak, jest fanem zamawiania
                        wszystkiego przez internet, więc tak też zrobił z pierścionkiem i co
                        najważniejsze, zrobił to z dużym odstępem czasowym, ponieważ aż 2
                        tygodnie przed wyjazdem. Pech chciał, że jubiler przeciągał wysyłkę i
                        finalnie pierścionek został wydany do doręczenia w momencie, gdy
                        siedzieliśmy już w samolocie. Na szczęście Damian się nie poddał i
                        dzień przed udało mu się kupić niemalże identyczny do wybranego
                        wcześniej pierścionka, stacjonarnie u innego jubilera. Dopiął swego!
                        Opowiada, że tak bardzo nie mógł się doczekać aż mi go da, że mógł to
                        zrobić od razu po kupnie, ale na szczęście wstrzymał się do 2giego
                        dnia pobytu w słonecznej i przepięknej Barcelonie.
                    </p>
                    <p>
                        Dzień zaręczyn był idealny, naprawdę. Od rana mieliśmy tak super
                        humory, że tego nie da się opisać, wszystko było dosłownie na “tak”.
                        Mieliśmy tego dnia kilka śmiesznych przygód i masę schodów do
                        pokonania, ale to nie popsuło nawet na chwilę Naszych humorów, nawet
                        mojego 😉
                    </p>
                    <p>
                        Jak już wcześniej wspomniałam najważniejsze pytanie zostało zadane
                        przy niesamowitym zachodzie słońca, którego nigdy oboje nie zapomnimy
                        i co najważniejsze! W ogóle się tego nie spodziewałam, na tyle mocno,
                        że pierwsze słowa, które odpowiedziałam to “żartujesz?” - nie
                        żartował.
                    </p>
                    <p>
                        I teraz Nasze życie od września kręci się głównie wokół planowania
                        Naszego najważniejszego dnia, którego już na maxa nie możemy się
                        doczekać!
                    </p>
                </TextContainer>
            </Column>
            <Column style={{gap: '2rem'}}>
                <HistoryButton onClick={() => setIsVideoDialogVisible(true)} sx={{backgroundImage: `url(${videoThumbnail})`, backgroundSize: 'cover'}}><SlideshowIcon
                    fontSize={'small'}/></HistoryButton>
                <HistoryButton onClick={() => setIsPhotoDialogVisible(true)} sx={{backgroundImage: `url(${imagesThumbnail})`, backgroundSize: 'cover'}}><PhotoIcon
                    fontSize={'small'}/></HistoryButton>
            </Column>
            <VideoDialog isOpened={isVideoDialogVisible} onClose={() => setIsVideoDialogVisible(false)}/>
            <PhotoDialog isOpened={isPhotoDialogVisible} onClose={() => setIsPhotoDialogVisible(false)}/>
        </div>)
}

export const TextContainer = styled('div')({
    padding: '2vh 6vw',
    borderRadius: '10px',
    boxShadow: '0px 0px 15px 2px rgba(143,188,143, 0.5)',
})

export const Column = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2vh 4vw'
})

export const HistoryButton = styled(IconButton)({
    width: 'max(15vw, 20vh)',
    aspectRatio: '1.5',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 0px 15px 2px rgba(143,188,143, 0.5);',
    borderRadius: '10px',
    svg: {
        fontSize: 'max(6vw, 6vh)'
    }
})