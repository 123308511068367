import {styled} from "@mui/material"
import {useDeviceType} from "../../hook/useDeviceType";

export interface DayElementProps {
    name: string,
    time?: string,
    address: string
}

export const DayElement = (elementProps: DayElementProps) => {
    const {isMobile} = useDeviceType();

    return (
        <DayElementContainer isMobile={isMobile}>
            <div> {elementProps.name}</div>
            <div style={{fontWeight: "bold", fontSize: isMobile ? "8vw" : "3vw"}}> {elementProps.time || ""}</div>
            <div> {elementProps.address}</div>
        </DayElementContainer>
    )
}

export const DayElementContainer = styled('div')<{ isMobile: boolean }>`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 15px 2px rgba(143, 188, 143, 0.5);
  width: ${props => (props.isMobile ? '45vw' : '15vw')};
  aspect-ratio: 0.7;
  font-size: ${props => (props.isMobile ? '4vw' : '1.2vw')};
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`