import React from "react";
import {StyledDialog} from "../../components/dialog/StyledDialog";
import ImageGallery, {ReactImageGalleryItem} from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

interface DialogProps {
    isOpened: boolean,
    onClose: () => void,
}

const images: ReactImageGalleryItem[] = Array.from({length: 104}, (_, i) => i + 1).map(ix => {
    return {original: './gallery/images/' + ix + '.webp', thumbnail: './gallery/thumbnails/' + ix + '_tn.jpg'};
})

export const PhotoDialog = (props: DialogProps) => {
    return <StyledDialog isOpened={props.isOpened} onClose={props.onClose}>
        <ImageGallery items={images} slideInterval={2000}></ImageGallery>
    </StyledDialog>
}