import {useMemo, useState} from "react";
import {TimeDisplay, TimeElement} from "./TimeDisplay";
import {Grid, styled} from "@mui/material";
import {DotsSeparator} from "./DotsSeparator";
import {useDeviceType} from "../../hook/useDeviceType";

interface DateTimeCounterProps {
    date: Date;
}

interface TimeLeftFormat {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const msInDay = 1000 * 60 * 60 * 24;
const msInHour = 1000 * 60 * 60;
const msInMinute = 1000 * 60;

export const DateTimeCounter = (props: DateTimeCounterProps) => {
    const {date} = props;
    const {isMobile} = useDeviceType();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const updateDate = () => {
        setCurrentDate(new Date());
    }

    const timeLeft = useMemo((): TimeLeftFormat => {
        const timeLeftMs = date.getTime() - currentDate.getTime();
        const days = Math.floor(timeLeftMs / msInDay);
        const hours = Math.floor((timeLeftMs % msInDay) / msInHour);
        const minutes = Math.floor(
            (timeLeftMs - hours * msInHour - days * msInDay) / msInMinute
        );
        const seconds = Math.floor(
            (timeLeftMs - hours * msInHour - days * msInDay - minutes * msInMinute) /
            1000
        );
        return {days: days, hours: hours, minutes: minutes, seconds: seconds};
    }, [date, currentDate]);

    setInterval(updateDate, 1000);

    return (
        <DownTimeCounter container id="DateTimeCounter" isMobile={isMobile}>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "center", width: '100%'}}>
                <TimeDisplay name={TimeElement.DAYS} value={timeLeft.days}/>
                <DotsSeparator/>
                <TimeDisplay name={TimeElement.HOURS} value={timeLeft.hours}/>
                <DotsSeparator/>
                <TimeDisplay name={TimeElement.MINUTES} value={timeLeft.minutes}/>
                <DotsSeparator/>
                <TimeDisplay name={TimeElement.SECONDS} value={timeLeft.seconds}/>
            </div>
            <div style={{paddingTop: 'min(2vh, 2vw)'}}>
                <DateText isMobile={isMobile}>{date.toLocaleDateString('pl-PL', {
                    day: "numeric",
                    month: 'long',
                    year: "numeric"
                }).toUpperCase()}</DateText>
            </div>
        </DownTimeCounter>
    );
};

export const DownTimeCounter = styled(Grid)<{ isMobile: boolean }>`
  justify-content: center;
  display: flex;
  align-items: stretch;
  width: ${props => props.isMobile ? '90vw' : '70vw'};
  color: white;
  z-index: 1;
  height: 25vh;
`

export const DateText = styled('div')<{ isMobile: boolean }>`
  font-size: ${props => props.isMobile ? '6vw' : '4vw'};
  font-weight: 600;
  z-index: -1;
`
