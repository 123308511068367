import {createTheme} from "@mui/material";

export const GlobalTheme = createTheme({
    typography: {
        fontSize: 20,
        fontFamily: 'Red Hat Display, sans-serif'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                color: white;
            }
            `
        }
    },
})
export const Theme = {
    colors: {
        black: 'rgb(0,0,0)',
        lightGrey: 'rgb(232,232,232)',
        borderColor: 'rgb(209,215,223)',
        lightBlue: 'rgb(69,102,131)',
        darkBlue: 'rgba(22,64,100,1)',
    }
}
