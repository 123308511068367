import {Box, styled} from "@mui/material";
import {NavigationItem} from "./NavigationItem";
import {PAGES} from "../../pages/pages";
import React, {useState} from "react";

export const Navigation = () => {
    const [selectedTab, setSelectedTab] = useState<string>('App');
    return <NavigationBox>
        <NavigationItem scrollTo={'App'} sectionName={"Odliczanie"} isSelected={selectedTab === 'App'}
                        changeSelectedTab={setSelectedTab}/>
        {PAGES.map(page => <NavigationItem scrollTo={page.elementId} sectionName={page.pageName}
                                           isSelected={selectedTab === page.elementId}
                                           changeSelectedTab={setSelectedTab}/>)}
    </NavigationBox>
}

export const NavigationBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    a: {
        borderRight: '1px solid',
        borderRadius: '0'
    },
    'a:last-child': {
        borderRight: '0'
    }

})