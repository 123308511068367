import {Box, styled} from "@mui/material";
import {Navigation} from "../navigation/Navigation";
import React from "react";
import {SCREEN_SIZE, useDeviceType} from "../../hook/useDeviceType";

interface HeaderProps {
    isPinned: boolean;
}

export const Header = (props: HeaderProps) => {
    const {isPinned} = props;
    const {isMobile, size} = useDeviceType();

    return (<HeaderBox sx={isPinned ? {backgroundColor: "rgba(143,188,143,0.9)"} : {}}>
        <TitleContainer isMobile={isMobile} size={size}>K&D's wedding</TitleContainer>
        <Navigation/>
    </HeaderBox>)
};

export const HeaderBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '1rem',
    position: 'sticky',
    top: '0',
    backdropFilter: 'blur(15px)',
    backgroundRepeat: `no-repeat`,
    backgroundSize: 'cover',
    width: '100%',
    zIndex: '2'
})

export const TitleContainer = styled('div')<{ isMobile: boolean, size?: SCREEN_SIZE }>`
  padding: 4vh;
  font-size: ${props => (props.isMobile ? '45px' : props.size && props.size === SCREEN_SIZE.SMALL_LAPTOP ? '90px' : '110px')};
  font-weight: 200;
  color: white;
`

