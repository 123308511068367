import {Box} from "@mui/material";
import {useDeviceType} from "../../hook/useDeviceType";

export const Footer = () => {
    const {isMobile} = useDeviceType();

    return (
        <Box sx={{
            width: '100%',
            height: '10vh',
            marginTop: '20px',
            backgroundColor: "rgba(143,188,143,0.9)",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: isMobile ? '3vw' : '2vw',
            letterSpacing: isMobile ? '0.2rem' : '0.4rem',
            color: '#FFF'
        }}>
            Widzimy się wkrótce! K&D
        </Box>
    )
}