import React from "react";
import {StyledDialog} from "../../components/dialog/StyledDialog";
import ReactPlayer from "react-player/lazy";
import {useDeviceType} from "../../hook/useDeviceType";
import Iframe from "react-iframe";

interface DialogProps {
    isOpened: boolean,
    onClose: () => void,
}

export const VideoDialog = (props: DialogProps) => {
    const {isMobile} = useDeviceType();

    return <StyledDialog isOpened={props.isOpened} onClose={props.onClose}>
        {isMobile ? <Iframe frameBorder={0}
                            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
                            referrerpolicy={"strict-origin-when-cross-origin"}
                            width={'300px'} height={'200px'} allowFullScreen
                            url={"https://www.youtube.com/embed/FwHFl-VumsU?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fkdwedding.pl&widgetid=1"}></Iframe>
            :
            <ReactPlayer width={'600px'} url={"https://www.youtube.com/watch?v=FwHFl-VumsU"}
                         controls={true} playing={props.isOpened}/>
        }
    </StyledDialog>
}